import type { BlockSeenOn } from 'types/block-seen-on';
import React, { useRef, useState } from 'react';
import { Heading, Link, Wrap } from '@leafwell/components';
import Image from 'next/image';

const SeenOn: React.FC<BlockSeenOn> = ({ title, items, fullWidth }) => {
  const scrollRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleMouseDown = e => {
    setIsDragging(true);
    setStartX(e.pageX - scrollRef.current.offsetLeft);
    setScrollLeft(scrollRef.current.scrollLeft);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = e => {
    if (!isDragging) return;
    e.preventDefault();
    const x = e.pageX - scrollRef.current.offsetLeft;
    const walk = x - startX;
    scrollRef.current.scrollLeft = scrollLeft - walk;
  };

  return (
    <Wrap className="justify-items-center justify-center gap-y-3 md:gap-y-3 xl:gap-y-3">
      {title && (
        <Heading
          level="2"
          className="uppercase text-sm text-center"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      )}
      <div
        className={fullWidth ? 'w-screen overflow-x-auto' : ''}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseMove={handleMouseMove}
      >
        <div
          className={[
            'flex justify-center items-center hide-scrollbar',
            fullWidth ? 'w-fit overflow-x-auto' : 'flex-wrap',
          ].join(' ')}
        >
          {items.map(({ logo, url }) => {
            const ItemWrap = url ? Link : React.Fragment;

            return (
              <ItemWrap
                key={logo.id}
                {...(url
                  ? { href: url, target: ':blank', rel: 'noopener noreferrer' }
                  : {})}
              >
                <Image
                  src={logo.src}
                  alt={logo.alt}
                  width={logo.width}
                  height={logo.height}
                  className="my-4 md:my-2 mx-4 sm:mx-6 xl:mx-10 w-20 sm:w-28 filter grayscale opacity-50"
                  quality={50}
                />
              </ItemWrap>
            );
          })}
        </div>
      </div>
    </Wrap>
  );
};

export default SeenOn;
